:root {
  --primary-color: #cadee5;
  --secondary-color: #2c3b57;
  --tertiary-color: #a5a5a5;
}

* {
  /* color: var(--secondary-color); */
  /* font-family: Verdana */
}

.top-banner {
  background-color: var(--secondary-color);
  color: var(--primary-color)
}

.navbar {
  align-self: end;
  /* background-color: var(--primary-color); */
}

.name-plate {
  padding: 5px;
  padding-left: 20px;
  min-width: 360px;
  text-align: left !important;
}

.avatar {
  margin-right: 10px;
}

.MuiPaper-root {
  background-color: var(--primary-color);
}

.first-name {
  color: var(--primary-color);
}

.last-name {
  color: var(--tertiary-color);
}

/* .name-sub {
  color: var(--tertiary-color);
} */
/* 
.MuiTableCell-root{
  border-bottom: none;
  border-width: "2";
  border-right: black;
} */
.testimonials-header {
  text-align: center;
}

.testimonial-author {
  font-weight: bold;
  text-align: right;
}

.testimonial-card {
  padding-bottom: 20px;
  margin: 10px
}

.testimonial-quote {
  padding: 15px;
}

.bold {
  font-weight: bold;
  word-wrap: normal;
}

.profile-pic {
  padding: 15px;
  border-radius: 200px;
  margin-right: 130px;
}

.skill-type {
  text-align: center;
  font-weight: bold;
}

.skill-details {
  text-align: center;
  padding: 10px
}

.thick-divider {
  border-width: 15px;
}

.value-card{
  width: 150px;
  text-align: center;
  background-color: var(--secondary-color);
}

@media screen and (max-width: 959px) {
  .profile-pic {
    margin-right: 0px
  }
}